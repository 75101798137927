export const fonts = {
  header: 'Acre',
  body: 'Acre',
}

export const fontSizes = {
  small: '0.75rem',
  regular: '1.0rem',
  medium: '1.5rem',
  large: '2.25rem',
}

export const colors = {
  primary: '#ff4500',
  background: '#1a1a1a',
  backgroundTransp: 'rgba(26, 26, 26, 0.5)',
  text: '#ccc',
}

export const darkTheme = {
  name: 'dark',
  primary: '#ff4500',
  background: '#000',
  backgroundTransp: 'rgba(0, 0, 0, 0.5)',
  blur: '10px',
  text: '#eee',
}

export const lightTheme = {
  name: 'light',
  primary: '#ff4500',
  background: '#eee',
  backgroundTransp: 'rgba(238, 238, 238, 0.5)',
  blur: '10px',
  text: '#1a1a1a',
}

export const spaces = {
  narrow: '0.75rem',
  regular: '1rem',
  wide: '1.45rem',
  spacer: '7rem',
  widthNav: '325px',
}

export const screenSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopS: '1920px',
  desktop: '2560px',
}
