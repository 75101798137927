module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alex Golunga","short_name":"Alex Golunga","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/assets/images/alex-golunga.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71a28c4562052f5cd8e26979c3c43758"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
